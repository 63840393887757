var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex items-center justify-center min-h-screen bg-white" },
    [
      _c(
        "div",
        {
          staticClass: "relative w-full max-w-md",
          staticStyle: { "min-height": "580px" },
        },
        [
          _c("div", { staticClass: "p-2" }, [
            _c("img", {
              staticClass: "pb-10 mx-auto",
              attrs: {
                width: "90px",
                src: require("@/assets/images/logo.png"),
                alt: "Nextar",
                loading: "lazy",
                "data-cy": "redefine-nex-img",
              },
            }),
            _c("div", { staticClass: "title1 my-4" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("recover_password.redefine_password_title")) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "text1 my-4" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("redefine_password.input_passwords")) + ": "
              ),
            ]),
            _c(
              "form",
              {
                staticClass: "w-full mt-8",
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.handlePasswordRecovery.apply(null, arguments)
                  },
                },
              },
              [
                _c("input", {
                  attrs: { type: "hidden", name: "remember", value: "true" },
                }),
                _c("div", { staticClass: "rounded-md shadow-sm" }, [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.recoverPasswordData.password1,
                          expression: "recoverPasswordData.password1",
                        },
                      ],
                      staticClass: "nex-input",
                      attrs: {
                        id: "password1",
                        minlength: "6",
                        maxlength: "12",
                        required: "",
                        type: "password",
                        placeholder: _vm.$t("redefine_password.new_password"),
                        "data-cy": "redefine-password1-input",
                      },
                      domProps: { value: _vm.recoverPasswordData.password1 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.recoverPasswordData,
                            "password1",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "rounded-md shadow-sm mt-3" }, [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.recoverPasswordData.password2,
                          expression: "recoverPasswordData.password2",
                        },
                      ],
                      staticClass: "nex-input",
                      attrs: {
                        id: "password2",
                        maxlength: "12",
                        minlength: "6",
                        required: "",
                        type: "password",
                        placeholder: _vm.$t("redefine_password.same_password"),
                        "data-cy": "redefine-password2-input",
                      },
                      domProps: { value: _vm.recoverPasswordData.password2 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.recoverPasswordData,
                            "password2",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _c("submit-button-default", {
                      attrs: {
                        loading: _vm.loading,
                        text: _vm.$t("send"),
                        "data-cy": "redefine-submit-btn",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }