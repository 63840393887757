<template>
    <div class="flex items-center justify-center min-h-screen bg-white">
        <div class="relative w-full max-w-md" style="min-height: 580px">
            <div class="p-2">
                <img
                    class="pb-10 mx-auto"
                    width="90px"
                    src="@/assets/images/logo.png"
                    alt="Nextar"
                    loading="lazy"
                    data-cy="redefine-nex-img"
                />

                <div class="title1 my-4">
                    {{ $t("recover_password.redefine_password_title") }}
                </div>

                <div class="text1 my-4">
                    {{ $t("redefine_password.input_passwords") }}:
                </div>

                <form
                    class="w-full mt-8"
                    method="post"
                    @submit.prevent="handlePasswordRecovery"
                >
                    <input type="hidden" name="remember" value="true" />
                    <div class="rounded-md shadow-sm">
                        <div>
                            <input
                                id="password1"
                                v-model="recoverPasswordData.password1"
                                minlength="6"
                                maxlength="12"
                                class="nex-input"
                                required
                                type="password"
                                :placeholder="
                                    $t('redefine_password.new_password')
                                "
                                data-cy="redefine-password1-input"
                            />
                        </div>
                    </div>
                    <div class="rounded-md shadow-sm mt-3">
                        <div>
                            <input
                                id="password2"
                                v-model="recoverPasswordData.password2"
                                maxlength="12"
                                minlength="6"
                                class="nex-input"
                                required
                                type="password"
                                :placeholder="
                                    $t('redefine_password.same_password')
                                "
                                data-cy="redefine-password2-input"
                            />
                        </div>
                    </div>

                    <div class="mt-3">
                        <submit-button-default
                            :loading="loading"
                            :text="$t('send')"
                            data-cy="redefine-submit-btn"
                        />
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { LOGIN_VIEW } from "@/router/constants";

import { mapActions } from "vuex";
import { LOGIN_MODULE, REDEFINE_PASSWORD } from "@/store/constants/login";
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";

export default {
    name: "Login",
    components: {
        SubmitButtonDefault,
    },
    data() {
        return {
            loading: false,
            token: "",
            recoverPasswordData: {
                password1: "",
                password2: "",
            },
        };
    },

    created() {
        if (this.$route.params?.token === undefined) {
            this.$router.push({ name: LOGIN_VIEW });
        }
        this.token = this.$route.params.token;
    },
    methods: {
        ...mapActions({
            redefinePasswordAction: `${LOGIN_MODULE}/${REDEFINE_PASSWORD}`,
        }),

        async handlePasswordRecovery() {
            if (!this.checkMatch()) {
                return;
            }

            this.loading = true;

            try {
                await this.redefinePasswordAction({
                    password: this.recoverPasswordData.password2,
                    token: this.token,
                });

                this.$fire({
                    text: this.$t("redefine_password.success_password_change"),
                    type: "success",
                    timer: 6000,
                });

                this.$router.push({ name: LOGIN_VIEW });
            } catch (error) {
                this.redefineErrorHandling(error);
            } finally {
                this.loading = false;
            }
        },
        checkMatch() {
            if (
                this.recoverPasswordData.password1 !==
                this.recoverPasswordData.password2
            ) {
                this.$fire({
                    text: this.$t("redefine_password.error_password"),
                    type: "error",
                    timer: 6000,
                });
                return false;
            }
            return true;
        },
        redefineErrorHandling(error) {
            let message = this.$t("redefine_password.error_redefine");
            this.$fire({
                text: message,
                type: "error",
                timer: 6000,
                customClass: "alert-error-1",
            });
            this.disableButton = false;
            throw new Error(JSON.stringify(error));
        },
    },
};
</script>
<style scoped>
.title1 {
    font-size: 25px;
    font-weight: 600;
    line-height: 1.25;
    color: #3c4858;
}

.text1 {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.25;
    color: #3c4858;
}
</style>
